import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import user, { User } from '@/shared/model/user';
import MyDataEditDialog from './my-data-edit/my-data-edit.vue';

const name = 'my-data-settings-view';
const logger = new Logger(name);
const authModule = namespace('auth');
const userModule = namespace('user');

@Component({ name: name, components: { MyDataEditDialog } })
export default class MyDataSettingsView extends Vue {
  user: User = user.parse({});

  @userModule.Action('getUser')
  private getUser!: any;
  @authModule.Getter('getAccount')
  private account!: any;
  @userModule.Action('updateInfoAndPassword')
  private updateInfoAndPassword!: any;
  @authModule.Action('signinSilent')
  private signinSilent!: any;

  async mounted() {
    this.user = await this.getUser(this.account.profile.sub);
  }

  mydataEditDialog: { show: boolean; model: User; isLoading: boolean } = {
    isLoading: false,
    show: false,
    model: { ...user.parse({}) },
  };
  async mydataEditDialogOnClose(mydata: User) {
    this.mydataEditDialog.show = false;
  }
  async mydataEditDialogOnUpdate(mydata: any) {
    this.mydataEditDialog.isLoading = true;

    await this.updateInfoAndPassword({
      id: this.account.profile.sub,
      info: {
        firstName: mydata.firstName,
        lastName: mydata.lastName,
        email: mydata.email,
        kurzform: mydata.kurzform,
        password: mydata.password,
        pdfZoom: mydata.pdfZoom,
        phoneNumber: mydata.phoneNumber,
      },
    })
      .then(async () => {
        await this.signinSilent(this.account.profile.current_organization_id);
        this.user = await this.getUser(this.account.profile.sub);
      })
      .finally((e: any) => {
        this.mydataEditDialog.isLoading = false;
        this.mydataEditDialog.show = false;
      });
  }
  onEdit() {
    this.mydataEditDialog.model = { ...this.user };
    this.mydataEditDialog.show = true;
  }

  private settingName(name: string) {
    switch (name) {
      case '1':
        return this.$t('automatic');
        break;
      case '2':
        return this.$t('original_size');
        break;
      case '3':
        return this.$t('page_size');
        break;
      case '4':
        return this.$t('page_width');
        break;
    }
  }
}
